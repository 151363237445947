import { Params } from '@angular/router';
import { Action, createReducer, on } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { RidersActions } from '.';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { RiderTripSearchResponse, RidersSearchResponse } from '../../shared/models/rider.vm.model';

export interface RidersState {
  isLoading: boolean;
  queryParams: Params;
  userPageSize: number;
  searchResponse: SearchResponse<RidersSearchResponse>;
  riders: RiderAggregate[];
  riderId: string;
  rider: RiderAggregate;
  tripSearchResponse: SearchResponse<RiderTripSearchResponse>;
  trips: BookingAggregate[];
}

export const initialState: RidersState = {
  isLoading: false,
  queryParams: null,
  userPageSize: null,
  searchResponse: null,
  riders: [],
  riderId: null,
  rider: null,
  tripSearchResponse: null,
  trips: [],
};

export const riderReducer = createReducer(
  initialState,
  on(RidersActions.queryParamsChanged, (state, action) => ({
    ...state,
    queryParams: action.queryParams,
    isLoading: true,
  })),
  on(RidersActions.pageChanged, (state, action) => ({
    ...state,
    userPageSize: action.page.pageSize,
  })),
  on(RidersActions.searchResponseFetched, (state, action) => ({
    ...state,
    searchResponse: action.searchResponse,
  })),
  on(RidersActions.riderListFetched, (state, action) => ({
    ...state,
    riders: action.riders,
    isLoading: false,
  })),
  on(RidersActions.ridersComponentDestroyed, (state, action) => ({
    ...state,
    riders: [],
    isLoading: false,
    queryParams: null,
    searchResponse: null,
  })),
  on(RidersActions.riderDetailsInit, (state, action) => ({
    ...state,
    isLoading: true,
    riderId: action.id,
  })),
  on(RidersActions.riderFetched, (state, action) => ({
    ...state,
    isLoading: false,
    rider: action.rider,
  })),
  on(RidersActions.riderTripsFetched, (state, action) => ({
    ...state,
    isLoading: false,
    trips: action.trips,
  })),
  on(RidersActions.riderTripSearchResponseFetched, (state, action) => ({
    ...state,
    tripSearchResponse: action.searchResponse,
  })),
  on(RidersActions.riderDetailsDestroyed, (state, action) => ({
    ...state,
    isLoading: false,
    rider: null,
    riderId: null,
    trips: [],
    tripSearchResponse: null,
  })),
  on(RidersActions.editRiderPhoneNumberButtonClicked, (state, _action) => ({
    ...state,
    isLoading: true,
  })),
  on(RidersActions.editRiderPhoneNumberFailed, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
  on(RidersActions.editRiderPhoneNumberSuccess, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
  on(RidersActions.deleteRiderButtonClicked, (state, _action) => ({
    ...state,
    isLoading: true,
  })),
  on(RidersActions.deleteRiderFailed, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
  on(RidersActions.deleteRiderSuccess, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
);

export function reducer(state: RidersState, action: Action) {
  return riderReducer(state, action);
}
