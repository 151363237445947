import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { SearchForm } from 'src/app/shared/models/elastic.vm.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { RiderTripSearchResponse, RidersSearchResponse } from '../../shared/models/rider.vm.model';

export const queryParamsChanged = createAction('[Rider list] Query params changed', props<{ queryParams: Params }>());

export const searchSubmitButtonClicked = createAction(
  '[Rider list] Search button clicked',
  props<{ searchForm: SearchForm }>(),
);

export const searchResponseFetched = createAction(
  '[Rider list] Search response fetched',
  props<{ searchResponse: SearchResponse<RidersSearchResponse> }>(),
);

export const riderListFetched = createAction('[Rider list] Rider list fetched', props<{ riders: RiderAggregate[] }>());

export const pageChanged = createAction('[Rider list] Page changed', props<{ page: PageEvent }>());

export const ridersComponentDestroyed = createAction('[Rider list] Component destroyed');

export const riderDetailsInit = createAction('[Rider details] Rider details init', props<{ id: string }>());

export const riderFetched = createAction('[Rider details] Rider fetched', props<{ rider: RiderAggregate }>());

export const riderDetailsDestroyed = createAction('[Rider details] Rider details destoyed');

export const riderTripsFetched = createAction(
  '[Rider details] Rider trip list fetched',
  props<{ trips: BookingAggregate[] }>(),
);

export const riderTripSearchResponseFetched = createAction(
  '[Rider details] Rider trip search response fetched',
  props<{ searchResponse: SearchResponse<RiderTripSearchResponse> }>(),
);

export const navigateToIntercomUserProfileButtonClicked = createAction(
  '[Rider details] Navigate to Intercom user profile button clicked',
  props<{ userId: string }>(),
);

export const allRiderTripsButtonClicked = createAction(
  '[Rider details] All rider trips button clicked',
  props<{ riderId: string }>(),
);

export const editRiderPhoneNumberButtonClicked = createAction(
  '[Rider details] Edit rider phone number button clicked',
  props<{ riderId: string; phoneNumber: string }>(),
);
export const editRiderPhoneNumberFailed = createAction('[Rider details] Edit rider phone number failed');
export const editRiderPhoneNumberSuccess = createAction('[Rider details] Edit rider phone number success');

export const deleteRiderButtonClicked = createAction(
  '[Rider details] Delete rider button clicked',
  props<{ riderId: string }>(),
);
export const deleteRiderFailed = createAction('[Rider details] Delete rider failed');
export const deleteRiderSuccess = createAction('[Rider details] Delete rider success');
